export const TAROT = [
  '00-TheFool.png',
  '01-TheMagician.png',
  '02-TheHighPriestess.png',
  '03-TheEmpress.png',
  '04-TheEmperor.png',
  '05-TheHierophant.png',
  '06-TheLovers.png',
  '07-TheChariot.png',
  '08-Strength.png',
  '09-TheHermit.png',
  '10-WheelOfFortune.png',
  '11-Justice.png',
  '12-TheHangedMan.png',
  '13-Death.png',
  '14-Temperance.png',
  '15-TheDevil.png',
  '16-TheTower.png',
  '17-TheStar.png',
  '18-TheMoon.png',
  '19-TheSun.png',
  '20-Judgement.png',
  '21-TheWorld.png',
  'CardBacks.png',
  'Cups01.png',
  'Cups02.png',
  'Cups03.png',
  'Cups04.png',
  'Cups05.png',
  'Cups06.png',
  'Cups07.png',
  'Cups08.png',
  'Cups09.png',
  'Cups10.png',
  'Cups11.png',
  'Cups12.png',
  'Cups13.png',
  'Cups14.png',
  'Pentacles01.png',
  'Pentacles02.png',
  'Pentacles03.png',
  'Pentacles04.png',
  'Pentacles05.png',
  'Pentacles06.png',
  'Pentacles07.png',
  'Pentacles08.png',
  'Pentacles09.png',
  'Pentacles10.png',
  'Pentacles11.png',
  'Pentacles12.png',
  'Pentacles13.png',
  'Pentacles14.png',
  'Swords01.png',
  'Swords02.png',
  'Swords03.png',
  'Swords04.png',
  'Swords05.png',
  'Swords06.png',
  'Swords07.png',
  'Swords08.png',
  'Swords09.png',
  'Swords10.png',
  'Swords11.png',
  'Swords12.png',
  'Swords13.png',
  'Swords14.png',
  'Wands01.png',
  'Wands02.png',
  'Wands03.png',
  'Wands04.png',
  'Wands05.png',
  'Wands06.png',
  'Wands07.png',
  'Wands08.png',
  'Wands09.png',
  'Wands10.png',
  'Wands11.png',
  'Wands12.png',
  'Wands13.png',
  'Wands14.png',
].map(c => '/tarot/' + c)

export const DECK_TEXT = `3 Contagion [ALL]
4 Dark Ritual [MI]
2 Demonic Consultation [ICE]
4 Drain Life [MI]
1 Hymn to Tourach <Danforth> [FEM]
4 Hypnotic Specter [4ED]
1 Ihsan's Shade [HML]
1 Ivory Tower [4ED]
2 Lake of the Dead [ALL]
2 Mind Warp [ICE]
2 Mishra's Factory [4ED]
4 Necropotence [ICE]
4 Nevinyrral's Disk [4ED]
2 Sengir Vampire [4ED]
1 Serrated Arrows [HML]
1 Strip Mine [4ED]
3 Stupor [MI]
18 Swamp <B> [ICE]
1 Zuran Orb [ICE]`

export const DECK_TEXT_2 = `4 Arcane Denial [ALL]
4 Birds of Paradise [LEB]
4 City of Brass [ARN]
2 Fire Whip [WL]
2 Force of Will [ALL]
1 Ghitu Slinger [UL]
1 Gilded Drake [UZ]
2 Llanowar Elves [LEB]
2 Merfolk Looter [EX]
1 Monk Realist [UZ]
3 Opposition [UD]
1 Peacekeeper [WL]
2 Quirion Ranger [MH2]
1 Radiant's Dragoons [UL]
4 Savannah [LEB]
1 Sliver Queen [ST]
2 Squee, Goblin Nabob [MM]
4 Survival of the Fittest [EX]
2 Taiga [LEB]
2 Tradewind Rider [TE]
4 Tropical Island [LEB]
1 Uktabi Orangutan [VI]
2 Undiscovered Paradise [VI]
1 Vampiric Tutor [VI]
4 Wall of Roots [MI]
3 Winter Orb [LEB]`

export const DECK_TEXT_3 = `4 Anurid Brushhopper [JUD]
4 Birds of Paradise [7E]
4 Brushland [7E]
4 Call of the Herd [OD]
2 City of Brass [7E]
3 Fire/Ice [AP]
4 Flametongue Kavu [PS]
1 Forest [7E]
1 Forest [7E]
1 Forest [7E]
1 Forest [7E]
1 Forest [OD]
1 Forest [OD]
1 Forest [OD]
3 Glory [JUD]
4 Karplusan Forest [7E]
3 Living Wish [JUD]
3 Llanowar Elves [7E]
1 Mountain [OD]
1 Mountain [OD]
4 Phantom Centaur [JUD]
1 Plains [OD]
1 Plains [OD]
1 Plains [7E]
2 Wax/Wane [IN]
4 Wild Mongrel [OD]`

export const SMALL_DECK = `
1 Demonic Consultation [ICE]
1 Drain Life [MI]
1 Hymn to Tourach <Danforth> [FEM]
1 Hypnotic Specter [4ED]
1 Ihsan's Shade [HML]
1 Ivory Tower [4ED]
1 Zuran Orb [ICE]`

export const ONE_OF_EACH = `1 Arcane Denial [ALL]
1 Birds of Paradise [LEB]
1 City of Brass [ARN]
1 Fire Whip [WL]
1 Force of Will [ALL]
1 Ghitu Slinger [UL]
1 Gilded Drake [UZ]
1 Llanowar Elves [LEB]
1 Merfolk Looter [EX]
1 Monk Realist [UZ]
1 Opposition [UD]
1 Peacekeeper [WL]
1 Quirion Ranger [MH2]
1 Radiant's Dragoons [UL]
1 Savannah [LEB]
1 Sliver Queen [ST]
1 Squee, Goblin Nabob [MM]
1 Survival of the Fittest [EX]
1 Taiga [LEB]
1 Tradewind Rider [TE]
1 Tropical Island [LEB]
1 Uktabi Orangutan [VI]
1 Undiscovered Paradise [VI]
1 Vampiric Tutor [VI]
1 Wall of Roots [MI]
1 Winter Orb [LEB]`


export const ZUR_DECK =  `1 Zur, Eternal Schemer (DMU)
1 Command Tower (ANB)
1 Circle of Confinement (VOW)
1 Curse of Silence (MID)
1 Michiko's Reign of Truth (NEO)
1 Spirited Companion (NEO)
1 Borrowed Time (MID)
1 The Fall of Lord Konda (NEO)
1 The Restoration of Eiganjo (NEO)
1 Touch the Spirit Realm (NEO)
1 Wedding Announcement (VOW)
1 Prayer of Binding (DMU)
1 Make Disappear (SNC)
1 Leyline Binding (DMU)
1 Lord Skitter's Blessing (WOE)
1 Temporary Lockdown (DMU)
1 The Meathook Massacre (MID)
1 Raffine's Tower (SNC)
1 Shipwreck Marsh (MID)
1 Shattered Sanctum (VOW)
1 Caves of Koilos (DMU)
1 Deserted Beach (MID)
1 Adarkar Wastes (DMU)
3 Plains (DMU)
3 Swamp (DMU)
2 Island (DMU)
1 Virtue of Loyalty (WOE)
1 Virtue of Persistence (WOE)
1 Heliod, the Radiant Dawn (MOM)
1 Invasion of Theros (MOM)
1 Takenuma, Abandoned Mire (NEO)
1 Otawara, Soaring City (NEO)
1 Eiganjo, Seat of the Empire (NEO)
1 Wash Away (VOW)
1 Darkslick Shores (ONE)
1 Underground River (BRO)
1 Phyrexian Arena (ONE)
1 Case of the Stashed Skeleton (MKM)
1 Makeshift Binding (MKM)
1 Seal from Existence (MOM)
1 The Long Reach of Night (NEO)
1 Go for the Throat (BRO)
1 Lost in the Maze (MKM)
1 Cut Down (DMU)
1 Restless Anchorage (LCI)
1 Seachrome Coast (ONE)
1 Proft's Eidetic Memory (MKM)
1 The Modern Age (NEO)
1 Restless Reef (LCI)
1 Restless Fortress (WOE)
1 Planar Disruption (ONE)
1 Invasion of Gobakhan (MOM)
1 Life of Toshiro Umezawa (NEO)
1 Disdainful Stroke (WOE)
1 Painful Quandary (BRO)`

export const LOREM = [
  'Lorem',
  'Ipsum',
  'Dolor',
  'Sit',
  'Amet',
  'Consectetur',
  'Adipiscing',
  'Elit',
  'Sed',
  'Do',
  'Eiusmod',
  'Tempor',
  'Incididunt',
  'Labore',
  'Et',
  'Dolore',
  'Magna',
  'Aliqua',
  'Ut',
  'Enim',
  'Ad',
  'Minim',
  'Veniam',
  'Quis',
  'Nostrud',
  'Exercitation',
  'Ullamco',
  'Laboris',
  'Nisi',
  'Aliquip',
  'Ex',
  'Ea',
  'Commodo',
  'Consequat',
  'Duis',
  'Aute',
  'Irure',
  'In',
  'Reprehenderit',
  'Voluptate',
  'Velit',
  'Esse',
  'Cillum',
  'Fugiat',
  'Nulla',
  'Pariatur',
  'Excepteur',
  'Sint',
  'Occaecat',
  'Cupidatat',
  'Non',
  'Proident',
  'Sunt',
  'Culpa',
  'Qui',
  'Officia',
  'Deserunt',
  'Mollit',
  'Anim',
  'Id',
]

// export const TAROT = [
//   'https://upload.wikimedia.org/wikipedia/commons/f/f5/RWS_Tarot_08_Strength.jpg',
//   'https://upload.wikimedia.org/wikipedia/commons/5/53/RWS_Tarot_16_Tower.jpg',
//   'https://upload.wikimedia.org/wikipedia/commons/9/9b/RWS_Tarot_07_Chariot.jpg',
//   'https://upload.wikimedia.org/wikipedia/commons/3/3a/TheLovers.jpg',
//   'https://upload.wikimedia.org/wikipedia/commons/thumb/8/88/RWS_Tarot_02_High_Priestess.jpg/690px-RWS_Tarot_02_High_Priestess.jpg',
//   'https://upload.wikimedia.org/wikipedia/commons/d/de/RWS_Tarot_01_Magician.jpg',
// ]
