import React from 'react'
import { animated, useSpring } from '@react-spring/web'
import { useGesture } from '@use-gesture/react'

const GrabberButton = (props: any) => {
  const defaultStyle = {
    height: 17,
    borderRadius: 8,
    backgroundColor: '#cccccc33',
    border: 'none',
    margin: '4px',
    width: 'calc(100% - 16px)',
  }
  const style = { ...defaultStyle, ...props.style }
  return (
    <animated.button {...props} style={style}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ color: 'white', transform: `rotate(90deg)` }}>
        <path
          d="M5.5 4.625C6.12132 4.625 6.625 4.12132 6.625 3.5C6.625 2.87868 6.12132 2.375 5.5 2.375C4.87868 2.375 4.375 2.87868 4.375 3.5C4.375 4.12132 4.87868 4.625 5.5 4.625ZM9.5 4.625C10.1213 4.625 10.625 4.12132 10.625 3.5C10.625 2.87868 10.1213 2.375 9.5 2.375C8.87868 2.375 8.375 2.87868 8.375 3.5C8.375 4.12132 8.87868 4.625 9.5 4.625ZM10.625 7.5C10.625 8.12132 10.1213 8.625 9.5 8.625C8.87868 8.625 8.375 8.12132 8.375 7.5C8.375 6.87868 8.87868 6.375 9.5 6.375C10.1213 6.375 10.625 6.87868 10.625 7.5ZM5.5 8.625C6.12132 8.625 6.625 8.12132 6.625 7.5C6.625 6.87868 6.12132 6.375 5.5 6.375C4.87868 6.375 4.375 6.87868 4.375 7.5C4.375 8.12132 4.87868 8.625 5.5 8.625ZM10.625 11.5C10.625 12.1213 10.1213 12.625 9.5 12.625C8.87868 12.625 8.375 12.1213 8.375 11.5C8.375 10.8787 8.87868 10.375 9.5 10.375C10.1213 10.375 10.625 10.8787 10.625 11.5ZM5.5 12.625C6.12132 12.625 6.625 12.1213 6.625 11.5C6.625 10.8787 6.12132 10.375 5.5 10.375C4.87868 10.375 4.375 10.8787 4.375 11.5C4.375 12.1213 4.87868 12.625 5.5 12.625Z"
          fill="currentColor"
          fillRule="evenodd"
          clipRule="evenodd"
        />
      </svg>
    </animated.button>
  )
}

const DeckHandler = ({ children, x: defaultX, y: defaultY }: any) => {
  const buttonRef = React.useRef<HTMLDivElement>(null!)
  const containerRef = React.useRef<HTMLDivElement>(null!)

  const isVisible = React.useRef(false)

  const [{ x, y, opacity }, api] = useSpring(
    () => ({
      x: defaultX,
      y: defaultY,
      opacity: 0,
    }),
    []
  )

  const getBounds = React.useCallback(() => {
    const { height, width } = containerRef.current.getBoundingClientRect()

    // console.log('getBounds', height, width)
    return {
      top: 0,
      left: 0,
      right: window.innerWidth - width,
      bottom: window.innerHeight - height,
    }
  }, [])

  const backgroundTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>()

  const bindGestures = useGesture(
    {
      onDrag: ({ down, offset: [ox, oy], velocity: [vx, vy], direction: [dx, dy] }) => {
        // console.log('onDrag', down)

        api.start({
          x: ox,
          y: oy,
          immediate: down,
          onChange: ({ value }) => {
            const bounds = getBounds()
            if (
              !(value.x >= bounds.left && value.x <= bounds.right && value.y >= bounds.top && value.y <= bounds.bottom)
            ) {
              api.set({
                x: value.x < bounds.left ? bounds.left : value.x > bounds.right ? bounds.right : value.x,
                y: value.y < bounds.top ? bounds.top : value.y > bounds.bottom ? bounds.bottom : value.y,
              })
            }
          },
          config: key => {
            return {
              velocity: key === 'x' ? vx * dx : vy * dy,
              decay: 0.99,
            }
          },
        })
      },
      onHover: ({ hovering }) => {
        // console.log('hovering', hovering)
        if (hovering) {
          if (backgroundTimeoutRef.current) {
            clearTimeout(backgroundTimeoutRef.current)
          }

          isVisible.current = true

          api.start({
            opacity: 1,
          })
        } else {
          backgroundTimeoutRef.current = setTimeout(() => {
            api.start({
              opacity: 0,
            })
          }, 200)
        }
      },
    },
    {
      drag: {
        from: () => [x.get(), y.get()],
        bounds: getBounds,
        rubberband: true,
      },
    }
  )

  const { onPointerEnter, onPointerLeave, onPointerDown, ...restGestures } = bindGestures()

  const handlePointerDown = (isBackground: boolean) => (e: React.PointerEvent<HTMLElement>) => {
    if (isBackground && !isVisible.current) {
      return
    }

    if (onPointerDown) {
      onPointerDown(e)
    }
  }
  // console.log('yo', containerRef)
  return (
    <animated.div
      ref={containerRef}
      onPointerLeave={onPointerLeave}
      //   onPointerDown={handlePointerDown(true)}
      //   {...restGestures}
      style={{
        position: 'absolute',
        padding: 12,
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
        backdropFilter: 'blur(8px)',
        alignItems: 'center',
        touchAction: 'none',
        width: '200px',
        height: '200px',
        x,
        y,
        backgroundColor: opacity.to(o => `rgba(0,0,0,${0.2 * o})`),
      }}>
      <GrabberButton onPointerDown={handlePointerDown(true)} {...restGestures} style={{ opacity }}></GrabberButton>
      <animated.div
        ref={buttonRef}
        onPointerEnter={onPointerEnter}
        // onPointerDown={handlePointerDown(false)}
        // {...restGestures}
        style={{
          border: 'none',
          position: 'relative',
          backgroundClip: 'content-box',
          zIndex: 0,
          //   touchAction: 'none',
        }}>
        {children}
        {/* <div style={{ height: '100px', width: '100px', backgroundColor: 'red' }}></div> */}
      </animated.div>
    </animated.div>
  )
}

export default DeckHandler
