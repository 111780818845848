
import { useState, useEffect } from 'react'


export const fetchCards = async (deckText: string) => {
  const fetchCard = async (cardName: any, set: any) => {
    let url = `https://api.scryfall.com/cards/named?fuzzy=${cardName}`
    // if (set) {
    //   url += `&set=${set}`
    // }
    const response = await fetch(url)
    return await response.json()
  }
  const deckParsed = deckText
    .trim()
    .split('\n')
    .map(c => c.match(/(\d+)\s(.+)\s[\[\(](.+)[\)\]]/) as any)
    .map(([_text, count, name, set]) => ({
      count: parseInt(count),
      name,
      set,
    }))
  const cards = await Promise.all(
    deckParsed.map(async (card: any) => {
      const cardDesc = await fetchCard(card.name, card.set)
      return Array(card.count).fill(cardDesc)
    })
  )
  return cards
}

export const useMTGDeck = (deckText : string ) => {
    const [cards, setCards] = useState([])
    useEffect(() => {
      (async () => {
        const fetchedCards = await fetchCards(deckText)
        setCards(fetchedCards as any)
      })()
    }, [deckText])
    if (!cards.length) {
      return []
    }
    return cards.flatMap((c: any) => c).map(c => c?.image_uris?.normal ||  c?.card_faces?.[0].image_uris?.normal || './notfound.jpg');
}