import { pullAt } from "lodash";

const insert = function (deck: string[], index: number, item: string) {
  deck.splice(index, 0, item);
};

export const moveDeck = (
  decks: string[][],
  deckFrom: number,
  deckFromCard: number,
  deckTo: number,
  deckToCard: number
) => {
  const pulledCards = pullAt(decks[deckFrom], [deckFromCard]);
  decks[deckTo] = decks[deckTo].concat(pulledCards);
  insert(decks[deckTo], deckToCard, pulledCards[0]);
  if (decks[deckFrom].length === 0) {
    pullAt(decks, deckFrom);
  }
  return decks;
};
