import React, { useState } from "react";
import styles from "./styles.module.css";
import {
  LOREM,
  SMALL_DECK,
  DECK_TEXT,
  ONE_OF_EACH,
  DECK_TEXT_2,
  DECK_TEXT_3,
  TAROT,
  ZUR_DECK,
} from "./DeckDefinitions";
import { Deck } from "./Deck";
import { useMTGDeck } from "./useMTGDeck";
import { shuffle, clone } from "lodash";
import DeckHandler from "./DeckHandler";
import { moveDeck } from "./deckState";

const tarot = shuffle(TAROT);

function App({ initialDecks }: { initialDecks: string[][] }) {
  const [decks, setDecks] = useState(initialDecks);
  return (
    <>
      <DeckHandler x={50} y={600}>
        <Deck items={LOREM.map((_l) => "./cardback.jpg")} texts={LOREM} />
      </DeckHandler>

      <DeckHandler x={50} y={10}>
        <Deck
          items={tarot}
          width={170 * 1.5}
          height={297 * 1.5}
          border={"10px solid white"}
        />
      </DeckHandler>

      <div className={styles.container}>
        {decks.map((d, i) => (
          <DeckHandler key={i} x={400 + i * 300} y={50}>
            <Deck key={d.join(",")} items={d}></Deck>{" "}
          </DeckHandler>
        ))}
      </div>
      <div style={{ position: "absolute", top: "10px", left: "900px" }}>
        <button
          onClick={() => {
            const [_firstDeck, ...rest] = decks;
            setDecks(
              [["./cardback.jpg"].concat(clone(_firstDeck))].concat(rest)
            );
          }}
        >
          Add card
        </button>
        <button
          onClick={() => {
            // const [_firstDeck, ...rest] = decks
            const deckFrom = 0;
            const deckFromCard = 0;
            const deckTo = 1;
            const deckToCard = 0;

            setDecks(
              moveDeck(clone(decks), deckFrom, deckFromCard, deckTo, deckToCard)
            );
          }}
        >
          Move deck right
        </button>
      </div>
    </>
  );
}

export default function AppLoadDecks() {
  const deck = useMTGDeck(DECK_TEXT);
  const smallDeck = useMTGDeck(SMALL_DECK);
  const oneOfDeck = useMTGDeck(ONE_OF_EACH);
  const deck2 = useMTGDeck(DECK_TEXT_2);
  const zurDeck = useMTGDeck(ZUR_DECK);
  const decks = [smallDeck, oneOfDeck, shuffle(deck), shuffle(deck2), zurDeck];

  // const decks = [zurDeck]
  const allLoaded = decks.every((d) => d.length > 0);
  if (!allLoaded) {
    return null;
  }
  return <App initialDecks={decks}></App>;
}
