import React, { Dispatch, SetStateAction, useRef } from 'react'
import { useSprings, animated } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'

import { clamp } from 'lodash'
import swap from 'lodash-move'
import styles from './styles.module.css'

const DIST = 1 // distance between each card in a deck when idle
const MIN_DECK_SPLIT_OFFSET = 30
const fn =
  (
    order: number[], // The current order. starts with natural order [0, 1, 2, 3, ...]
    active = false, // determines if we're currently dragging
    outside = false, // outside the range of the deck in x-axis
    dragOriginalIndex = 0, // original index of the currently dragging object
    dragCurIndex = 0, // the newIndex of the currently dragging object
    x = 0, // x position of the mouse
    y = 0 // y position of the mouse
  ) =>
  (index: number, _controller: any) => {
    const splitOffset = Math.max(MIN_DECK_SPLIT_OFFSET, order.length) // Some kind of heuristic. As the deck grows bigger, it splits more
    const curIndex = order.indexOf(index) // The current index of the card we're iterating on

    const dist = DIST
    const dragNewIndex = order.indexOf(dragOriginalIndex) // As we're dragging, this would be its new index

    return active && index === dragOriginalIndex
      ? {
          x,
          y: dragCurIndex * dist + y,
          scale: 1,
          zIndex: -dragNewIndex,
          shadow: 15,
          // dragging object should move with the mouse without lag
          immediate: (key: string) => key === 'x' || key === 'y' || key === 'zIndex',
        }
      : {
          x: 0,
          // offsetting part of the deck above dragging card
          y: curIndex * dist + (active && !outside && curIndex < dragNewIndex ? -splitOffset : 0),
          scale: 1,
          zIndex: -curIndex,
          shadow: 1,
          immediate: (key: string) => key === 'zIndex',
        }
  }
const distanceToNextRowY = DIST * 1.2

export function Deck({
  items = [],
  texts = [], // for debugging unique identifier
  height = 350,
  width = 250,
  border = '',
}: {
  items: string[]
  texts?: string[]
  height?: number
  width?: number
  border?: string
}) {
  const order = useRef(items.map((_, index) => index)) // Store indicies as a local ref, this represents the item order
  const [springs, api] = useSprings(items.length, fn(order.current), [items.length]) // Create springs, each corresponds to an item, controlling its transform, scale, etc.

  // https://use-gesture.netlify.app/docs/state/#gesture-state-attributes
  // args: arguments you passed to bind (React only)
  const bind = useDrag(({ args: [originalIndex], active, movement: [x, y] }) => {
    const curIndex = order.current.indexOf(originalIndex)
    const outside = Math.abs(x) > width * (1 / 2) + 10 // when the card isn't 2/3 covered by the deck it's considered outside
    // if(outside) {
    //   array.splice(index, 1)
    // }
    const curRow = clamp(Math.round((curIndex * distanceToNextRowY + y) / distanceToNextRowY), 0, items.length - 1)

    // if card falls outside order is original index
    const newOrder = outside ? swap(order.current, curIndex, originalIndex) : swap(order.current, curIndex, curRow)
    api.start(fn(newOrder, active, outside, originalIndex, curIndex, x, y)) // Feed springs new style data, they'll animate the view without causing a single render
    if (!active) {
      order.current = newOrder
    }
  })
  return (
    <div
      className={styles.content}
      style={{ height: items.length * DIST, width /*position: 'absolute', left: x, top: y */ }}>
      {springs.map(({ zIndex, shadow, x, y, scale }, i) => (
        <animated.div
          {...bind(i)}
          key={i}
          children={texts[i]}
          style={{
            border,
            zIndex,
            height,
            width,
            boxShadow: shadow.to(s => `rgba(0, 0, 0, 0.15) 0px ${s}px ${2 * s}px 0px`),
            x,
            y,
            scale,
            backgroundImage: 'url(' + items[i] + ')',
          }}
        />
      ))}
    </div>
  )
}
